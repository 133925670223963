import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner6@2x.jpg'),
    heading: "For that one customer who constantly breaks all of the rules",
    subheading: "Quotes and Bespoke Plans gives your sales team the ability to build custom deals for customers, send them out and then get them implemented - in a way that won't annoy your developers (thanks to Feature Tags) or your accounts team (with safeguards like approval flows)."
  }

  return(
    <Layout>
      <SEO 
        title="Quotes and Bespoke Plans | Billsby Pro | Take your subscription business to the next level" 
        description="Quotes and Bespoke Plans and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/quotes-bespoke-plans"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;